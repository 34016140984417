import React from 'react';
import ReactDOM from 'react-dom/client';  // Updated import from 'react-dom/client'
import './index.css';
import App from './App';
import './i18n';

// Create a root.
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);  // Updated from ReactDOM.render

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
