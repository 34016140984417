import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe, GithubIcon } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

import LanguageSwitcher from './components/LanguageSwitcher';
import './App.css';

const ProjectCard = ({ project }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [transitionClass, setTransitionClass] = useState('');
  let animationFrame;

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTransitionClass('animate-pulse');
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setRotation({ x: 0, y: 0 });
    setTransitionClass('');
    cancelAnimationFrame(animationFrame);
  };

  const handleMouseMove = (e) => {
    if (!isHovered) return;
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const rotateX = (y - centerY) / 25;
    const rotateY = (centerX - x) / 25;

    animationFrame = requestAnimationFrame(() => {
      setRotation({ x: rotateX, y: rotateY });
    });
  };

  return (
    <div
      className={`bg-gradient-to-r from-cyan-600 to-blue-600 p-1 rounded-lg ${transitionClass} transform-gpu transition-transform duration-500 ease-out hover:shadow-lg`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{
        transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) ${
          isHovered ? 'scale(1.05)' : 'scale(1)'
        }`,
        boxShadow: isHovered ? `${rotation.y / 2}px ${rotation.x / 2}px 20px rgba(0, 0, 0, 0.2)` : 'none',
      }}
    >
      <div className="bg-cyan-800/60 backdrop-blur-md rounded-lg overflow-hidden">
        <img src={project.imageUrl} alt={`Project: ${project.title}`} className="w-full h-40 object-cover transform transition-transform duration-300 ease-out hover:scale-110" />
        <div className="p-6">
          <h3 className="text-xl font-semibold mb-2 text-white">{project.title}</h3>
          <p className="mb-4 text-white">{project.description}</p>
          <div className="flex space-x-4 mt-4">
            {/* GitHub 图标和链接 */}
            {/* <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="text-white hover:text-cyan-200 transition-colors"> */}
              {/* <GithubIcon size={24} /> */}
            {/* </a> */}
            {/* 网站图标和链接 */}
            <a href={project.websiteLink} target="_blank" rel="noopener noreferrer" className="text-white hover:text-cyan-200 transition-colors">
              <Globe size={24} />
            </a> 
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const { t } = useTranslation();
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    // Trigger the animation once the component mounts
    setIsAnimated(true);
  }, []);

  const projects = [
    {
      id: 1,
      title: t('projects.0.title'),
      description: t('projects.0.description'),
      imageUrl: t('projects.0.imageUrl'),
      githubLink: t('projects.0.githubLink'),
      websiteLink: t('projects.0.websiteLink'),
    },
    {
      id: 2,
      title: t('projects.1.title'),
      description: t('projects.1.description'),
      imageUrl: t('projects.1.imageUrl'),
      githubLink: t('projects.1.githubLink'),
      websiteLink: t('projects.1.websiteLink'),
    },
    {
      id: 3,
      title: t('projects.2.title'),
      description: t('projects.2.description'),
      imageUrl: t('projects.2.imageUrl'),
      githubLink: t('projects.2.githubLink'),
      websiteLink: t('projects.2.websiteLink'),
    },
    {
      id: 4,
      title: t('projects.3.title'),
      description: t('projects.3.description'),
      imageUrl: t('projects.3.imageUrl'),
      githubLink: t('projects.3.githubLink'),
      websiteLink: t('projects.3.websiteLink'),
    },
  ];

  return (
    <div className="min-h-screen font-sans overflow-hidden animated-background font-tech">
      <div className={`max-w-7xl mx-auto px-4 py-16 text-white ${isAnimated ? 'animate-fadeIn' : ''}`}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-8">
            <h1 className="custom-name-style">
              <span className="initial">S</span>HIJIA <span className="initial">G</span>UO
            </h1>
            <section className="bg-cyan-800/50 backdrop-blur-md rounded-lg p-8 transform transition-transform duration-500 hover:scale-105 mb-8 md:fixed sm:relative">
              <header className="text-center lg:text-left mb-8">
                <p className="text-xl mb-4">{t('profile.title')}</p>
                <LanguageSwitcher /> 
              </header>

              <div className="flex justify-center lg:justify-start space-x-8 mb-8">
                <a
                  href="https://www.linkedin.com/in/leoguo23/"
                  className="text-white hover:text-cyan-200 transition-colors"
                  target="_blank"
                  rel="noopener noreferrer" 
                >
                  <FontAwesomeIcon icon={faLinkedin} size="lg" />
                </a>
                <a href="mailto:sg2345@cornell.edu" className="text-white hover:text-cyan-200 transition-colors">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                </a>
                <a
                  href="https://github.com/LeoShijiaGuo"
                  className="text-white hover:text-cyan-200 transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGithub} size="lg" />
                </a>
              </div>
            </section>
          </div>

          <div>
            <h2 className="text-3xl font-semibold mb-6 text-cyan-200 glow-effect">{t('navigation.projects')}</h2>
            <div className="space-y-6">
              {projects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
